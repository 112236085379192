<template>
  <section class="recent-documents" :class="{ loading: loading }">
    <b-card ref="card" no-body>
      <loading
        :active.sync="loading"
        :is-full-page="false"
        :container="$refs.card"
        :width="40"
        :height="40"
        color="#ff686b"
      />
      <b-card-body>
        <b-card-title>{{ $t("documents.recentlyAdded") }}</b-card-title>
      </b-card-body>
      <b-list-group flush>
        <b-list-group-item
          v-for="doc in documents"
          :key="doc.id"
          class="px-xs-0"
        >
          <router-link :to="toViewRoute(doc)" class="d-block w-100">
            <h6 class="mb-1">
              <span>{{ doc.title }}</span>
              <span class="badge badge-secondary float-right">
                <i class="icofont-clock-time pr-1"></i>
                <time :datetime="doc.uploadedAt">{{
                  doc.uploadedAt | moment("from", "now")
                }}</time>
              </span>
            </h6>
          </router-link>
          <small class="mb-1"
            >{{ doc.category.name }} / {{ doc.subject.name }}</small
          >
        </b-list-group-item>
      </b-list-group>
      <b-alert
        v-if="!(loading || documents.length)"
        show
        variant="info"
        class="text-center pt-3 mb-0"
      >
        <i class="icofont-inbox icofont-2x"></i>
        <h5 class="mt-3">
          {{ $t("categories.empty") }}
        </h5>
      </b-alert>
    </b-card>
  </section>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import Loading from "vue-loading-overlay";

export default {
  name: "RecentDocumentsList",
  components: {
    Loading
  },
  data() {
    return {
      documents: [],
      loading: false
    };
  },
  computed: {
    ...mapState("categories", { category: "active" })
  },
  methods: {
    toViewRoute(doc) {
      return {
        name: "document.view",
        params: {
          category: doc.category.slug,
          subject: doc.subject.slug,
          document: doc.slug
        }
      };
    },
    async fetchRecentDocuments() {
      let category = (this.category && this.category.slug) || "";

      this.loading = true;
      const response = await axios.get(
        `/${this.$i18n.locale}/document/list/recent/${category}`
      );
      this.documents = response.data;
      this.loading = false;
    }
  },
  watch: {
    category: {
      handler: "fetchRecentDocuments",
      immediate: true
    }
  }
};
</script>
