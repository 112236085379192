import axios from "axios";
import Objects from "@/utils/objects";

export const state = {
  user: null,
  loaded: false
};

export const getters = {
  isAuthorized: state => !!state.user,
  isLoaded: state => !!state.loaded
};

export const mutations = {
  setUser: (state, user) => {
    state.user = user;
  },
  markAsLoaded: state => {
    state.loaded = true;
  }
};

export const actions = {
  async __init({ commit }) {
    try {
      const response = await axios.get("/user/info");
      commit("setUser", response.data);
    } catch (e) {
      if ([401, 403].includes(e.response.status)) {
        return;
      }

      // other error
      throw e;
    } finally {
      commit("markAsLoaded");
    }
  },
  async login({ commit }, credentials) {
    const config = {
      headers: { "content-type": "multipart/form-data" },
      withCredentials: true
    };
    const response = await axios.post(
      "/user/login",
      Objects.toFormData(credentials),
      config
    );

    if (response.status === 200) {
      commit("setUser", response.data);
    }
  },
  async logout({ commit }) {
    await axios.get("/user/logout");
    commit("setUser", null);
  },
  changeUserSettings: async function({ commit, state }, settings) {
    const config = {
      headers: { "content-type": "multipart/form-data" },
      withCredentials: true
    };
    await axios.post(
      "/user/change/settings",
      Objects.toFormData(settings),
      config
    );
    let { ...user } = state.user;

    user.name = settings.name;
    commit("setUser", user);
  }
};
