<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view :key="hash" />
    </transition>
  </div>
</template>

<script>
export default {
  name: "app",
  computed: {
    hash() {
      return this.$route.fullPath.replace(this.$route.hash, "");
    }
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.page {
  position: relative;
  width: inherit;
}
</style>
