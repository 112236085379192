<template>
  <layout :breadcrumbs="breadcrumbs">
    <section class="page-view vld-parent" ref="localRoot">
      <loading
        :active="loading"
        v-if="loading"
        :is-full-page="false"
        :container="$refs.localRoot"
        :width="50"
        :height="50"
        color="#ff686b"
      />

      <b-card style="min-height: 80px" :title="page.title">
        <div v-html="page.body"></div>
      </b-card>
    </section>
  </layout>
</template>

<script>
import Loading from "vue-loading-overlay";
import axios from "axios";
import Layout from "@/layouts/Default";

export default {
  name: "page",
  components: {
    Layout,
    Loading
  },
  data() {
    return {
      page: {
        title: "",
        body: ""
      },
      loading: true
    };
  },
  computed: {
    breadcrumbs() {
      if (this.loading) {
        return [];
      }

      return [
        {
          text: this.page.title,
          to: {
            name: "page.view",
            params: {
              locale: this.$i18n.locale,
              slug: this.$route.slug
            }
          }
        }
      ];
    }
  },
  methods: {
    async fetchPage() {
      try {
        const response = await axios.get(`/page/${this.$route.params.slug}`);
        this.page = response.data;
      } catch (e) {
        this.$router.push(`/${this.$i18n.locale}/404`);
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    this.fetchPage();
  }
};
</script>
