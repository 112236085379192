import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./moment";
import store from "./store";
import BootstrapVue from "bootstrap-vue";
import Vuelidate from "vuelidate";
import "vue-loading-overlay/dist/vue-loading.css";
import "./assets/css/app.scss";
import i18n from "@/i18n";
import axios from "axios";

axios.defaults.withCredentials = true;

Vue.use(BootstrapVue);
Vue.use(Vuelidate);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
