import axios from "axios";

export const state = {
  all: [],
  map: new Map(),
  slugMap: new Map(),
  active: null,
  loaded: false
};

export const mutations = {
  save: (state, subjects) => {
    state.all = subjects;
    subjects.map(subject => {
      state.map.set(subject.id, subject);
      state.slugMap.set(subject.slug, subject);
    });
  },
  markAsLoaded: state => {
    state.loaded = true;
  },
  markAsActive: (state, subject) => {
    state.active = subject;
  },
  markAsActiveWithSlug: (state, slug) => {
    state.active = state.slugMap.get(slug) || null;
  },
  unsetActive: state => {
    state.active = null;
  }
};

export const actions = {
  async __init({ commit }) {
    try {
      const locale = location.pathname.split("/").filter(x => !!x)[0];
      const response = await axios.get(`/${locale}/subject/list/all`);
      commit("save", response.data);
      commit("markAsLoaded");
    } finally {
      // do something
    }
  }
};
