import Vue from "vue";
import VueMoment from "vue-moment";
import * as moment from "moment";

moment.updateLocale("uz-latn", {
  longDateFormat: {
    L: "DD.MM.YYYY"
  },
  relativeTime: {
    past: "%s oldin"
  }
});

Vue.use(VueMoment, { moment });

export default moment;
