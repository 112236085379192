<template>
  <layout :breadcrumbs="breadcrumbs">
    <b-card class="document" v-if="loaded" no-body>
      <b-card-body>
        <b-card-title>{{ doc.title }}</b-card-title>
        <div class="row text-muted text-right">
          <div class="col-md-3 col-sm-6">
            <p class="mb-1">
              <i class="icofont-clock-time"></i>
              {{ $t("document.uploadedTime") }}:
            </p>
            <h5>{{ doc.uploadedAt | moment("L") }}</h5>
          </div>
          <div class="col-md-3 col-sm-6">
            <p class="mb-1">
              <i class="icofont-download-alt"></i>
              {{ $t("document.downloadsCount") }}:
            </p>
            <h5>{{ doc.downloads }}</h5>
          </div>
          <div class="col-md-2 col-sm-6">
            <p class="mb-1">
              <i class="icofont-hard-disk"></i>
              {{ $t("document.size") }}:
            </p>
            <h5>{{ fileSize(doc.metadata.file.size) }}</h5>
          </div>
          <div class="col-md-4 col-sm-6" style="text-align: center">
            <b-btn v-if="canDownload" :href="downloadLink" block class="mt-1">
              <i class="icofont-download"></i>
              {{ $t("document.download") }}
            </b-btn>
            <b-btn
              v-else-if="isAuthorized && !hasSubscription"
              :href="purchaseSubscriptionLink"
              block
              variant="info"
              class="mt-1"
            >
              <i class="icofont-download"></i>
              {{ $t("document.termsOfDownload") }}
            </b-btn>
            <b-btn
              v-else
              :href="termsOfDownloadLink"
              block
              variant="info"
              class="mt-1"
            >
              <i class="icofont-download"></i>
              {{ $t("document.termsOfDownload") }}
            </b-btn>
          </div>
        </div>
      </b-card-body>
      <document-preview :doc="doc" />
    </b-card>
    <b-card v-if="loaded && doc.similar.length" no-body>
      <b-card-body>
        <b-card-title class="mb-0">{{ $t("document.similar") }}</b-card-title>
      </b-card-body>
      <b-list-group flush>
        <b-list-group-item v-for="similar in doc.similar" :key="similar.id">
          <b-link :to="toDocumentRoute(similar)">
            <i
              class="icofont-2x mr-2"
              style="vertical-align: middle"
              :class="
                fileExtensionIcon(similar.metadata.file.extension.toLowerCase())
              "
            ></i>
            {{ similar.title }}
          </b-link>
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </layout>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import DocumentPreview from "../components/DocumentPreview";
import Layout from "@/layouts/Default";

export default {
  name: "Document",
  components: { DocumentPreview, Layout },
  data() {
    return {
      loaded: false,
      doc: null,
      timeFormatter: null
    };
  },
  computed: {
    ...mapState({
      categories: state => state.categories.map,
      subjects: state => state.subjects.map,
      user: state => state.user.user
    }),
    category() {
      return this.categories.get(this.doc.category);
    },
    subject() {
      return this.subjects.get(this.doc.subject);
    },
    breadcrumbs() {
      if (!this.loaded || !this.category || !this.subject) {
        return [];
      }

      return [
        {
          text: this.category.name,
          to: {
            name: "category.view",
            params: {
              category: this.category.slug
            }
          }
        },
        {
          text: this.subject.name,
          to: {
            name: "subject.view",
            params: {
              category: this.category.slug,
              subject: this.subject.slug
            }
          }
        },
        {
          text: this.doc.title,
          to: {
            name: "document.view",
            params: {
              category: this.category.slug,
              subject: this.subject.slug,
              document: this.doc.slug
            }
          }
        }
      ];
    },
    isAuthorized() {
      return !!this.user;
    },
    hasSubscription() {
      return !!(this.isAuthorized && this.user.subscription);
    },
    canDownload() {
      if (!this.isAuthorized) {
        return false;
      }

      // if user is admin or root
      if (this.user.role && this.user.role !== "ordinary") {
        return true;
      }

      return this.hasSubscription;
    },
    downloadLink() {
      return !this.loaded
        ? "#"
        : `/${this.$i18n.locale}/download/${this.category.slug}/${
            this.subject.slug
          }/${this.doc.slug}`;
    },
    termsOfDownloadLink() {
      if (this.$i18n.locale === "uz") {
        return "/uz/page/qanday-qilib-obuna-bolish-mumkin";
      }

      if (this.$i18n.locale === "ru") {
        return "/uz/page/kak-priobresti-podpisku";
      }

      return "#";
    },
    purchaseSubscriptionLink() {
      return `/${this.$i18n.locale}/user/subscriptions`;
    }
  },
  methods: {
    async getDocument(url) {
      const response = await axios.get(url);
      this.doc = response.data;
      document.title = this.doc.title;
      this.loaded = true;
    },
    log(number, base) {
      return Math.log(number) / Math.log(base);
    },
    fileSize(bytes) {
      if (bytes < 1) {
        return 0;
      }

      const { log, floor, pow } = Math;
      const power = floor(log(bytes) / log(1024));
      const value = (bytes / pow(1024, power)).toFixed(1);

      return `${value} ${process.env.VUE_APP_BYTE_UNITS.split(",")[power]}`;
    },
    toDocumentRoute(doc) {
      return {
        name: "document.view",
        params: {
          category: doc.category.slug,
          subject: doc.subject.slug,
          document: doc.slug
        }
      };
    },
    fileExtensionIcon(ext) {
      if (["doc", "docx"].includes(ext)) {
        return "icofont-file-word text-info";
      }

      if (["ppt", "pptx"].includes(ext)) {
        return "icofont-file-powerpoint";
      }

      if (ext === "pdf") {
        return "icofont-file-pdf";
      }

      return "icofont-file-document";
    }
  },
  created() {
    const { category, subject, document } = this.$route.params;
    this.getDocument(`/documents/${category}/${subject}/${document}`);
  },
  beforeRouteUpdate(to, from, next) {
    this.getDocument(to.path);
    next();
  }
};
</script>
