class Objects {
  static toFormData(data) {
    let result = new FormData();

    for (let property in data) {
      if (data.hasOwnProperty(property)) {
        result.append(property, data[property]);
      }
    }

    return result;
  }
}

export default Objects;
