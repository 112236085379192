import axios from "axios";

export const state = {
  all: [],
  map: new Map(),
  slugMap: new Map(),
  active: null,
  loaded: false
};

export const mutations = {
  save(state, categories) {
    state.all = categories;
    categories.map(category => {
      state.map.set(category.id, category);
      state.slugMap.set(category.slug, category);
    });
  },
  markAsLoaded: state => {
    state.loaded = true;
  },
  markAsActive: (state, category) => {
    state.active = category;
  },
  markAsActiveWithSlug: (state, slug) => {
    state.active = state.slugMap.get(slug) || null;
  },
  unsetActive: state => {
    state.active = null;
  }
};

export const actions = {
  async __init({ commit }) {
    const locale = location.pathname.split("/").filter(x => !!x)[0];
    const response = await axios.get(`/${locale}/category/list/all`);
    commit("save", response.data);
    commit("markAsLoaded");
  }
};
