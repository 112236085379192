<template>
  <layout :breadcrumbs="breadcrumbs">
    <section class="search vld-parent" ref="page">
      <loading
        :active.sync="isLoading"
        :is-full-page="false"
        :container="$refs.page"
        :width="50"
        :height="50"
        color="#ff686b"
      />

      <p v-if="hasHits" class="text-muted small">
        {{ amountMessage }} {{ $t("search.elapsedTime", { eta: eta }) }}
      </p>

      <b-card v-for="hit in hits" :key="hit.id" class="search-hit">
        <b-card-title title-tag="h6">
          <b-link :to="documentViewRoute(hit)">{{ hit.title }}</b-link>
        </b-card-title>
        <b-breadcrumb class="m-0 p-0">
          <b-breadcrumb-item :to="categoryViewRoute(hit)">
            {{ categories.get(hit.category.id).name }}
          </b-breadcrumb-item>
          <b-breadcrumb-item :to="subjectViewRoute(hit)">
            {{ subjects.get(hit.subject.id).name }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </b-card>

      <b-card v-if="hasNoHits" bg-variant="warning" align="center">
        <b-card-title class="icofont-search-folder" title-tag="h1" />
        <b-card-text>
          {{ $t("search.noResult") }}
        </b-card-text>
      </b-card>

      <b-pagination
        v-if="isPaginationNeeded"
        v-model="page"
        :total-rows="total.value"
        :per-page="pageSize"
        :first-text="$t('pagination.first')"
        :prev-text="$t('pagination.prev')"
        :next-text="$t('pagination.next')"
        :last-text="$t('pagination.last')"
        align="center"
      />
    </section>
  </layout>
</template>

<script>
import Layout from "../layouts/Default";
import axios from "axios";
import { required, minLength } from "vuelidate/lib/validators";
import Loading from "vue-loading-overlay";
import { mapState } from "vuex";

export default {
  name: "Search",
  components: {
    Layout,
    Loading
  },
  data() {
    return {
      query: this.$route.query.query || "",
      hits: [],
      total: {
        value: -1,
        relation: "eq"
      },
      took: 0,
      pageSize: this.$route.query.pageSize || 10,
      page: this.$route.query.page || 1,
      isLoading: true
    };
  },
  validations: {
    query: {
      required,
      minLength: minLength(3)
    }
  },
  computed: {
    ...mapState({
      categories: state => state.categories.map,
      subjects: state => state.subjects.map,
      loaded: state => !!(state.subjects.loaded && state.categories.loaded)
    }),
    isValid() {
      this.$v.query.$touch();
      return !this.$v.query.$invalid;
    },
    hasHits() {
      return !this.isLoading && this.total.value > 0;
    },
    hasNoHits() {
      return !this.isLoading && this.total.value === 0;
    },
    isPaginationNeeded() {
      return !this.isLoading && this.total.value > this.pageSize;
    },
    amountMessage() {
      let message = this.$t("search.result.total", {
        count: this.total.value
      });

      if (this.total.relation !== "eq") {
        message = this.$t("search.result.totalAbout", {
          count: this.total.value
        });
      }

      return message;
    },
    eta() {
      let eta = (this.took / 1000).toFixed(2);

      if (eta === "0.00") {
        eta = (this.took / 1000).toFixed(3);
      }

      return eta;
    },
    params() {
      return {
        query: this.query,
        pageSize: this.pageSize,
        page: this.page
      };
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("search.title"),
          path: this.$route.path
        }
      ];
    }
  },
  methods: {
    async search() {
      if (!this.isValid) {
        return;
      }

      try {
        this.isLoading = true;
        const response = await axios.get(`/search`, {
          params: this.params
        });
        this.hits = response.data.hits;
        this.total = response.data.total;
        this.took = response.data.took;
      } finally {
        this.isLoading = false;
      }
    },
    changePage() {
      this.$router.replace({ name: "search", query: this.params });
    },
    documentViewRoute(doc) {
      return {
        name: "document.view",
        params: {
          category: doc.category.slug,
          subject: doc.subject.slug,
          document: doc.slug
        }
      };
    },
    categoryViewRoute({ category }) {
      return {
        name: "category.view",
        params: {
          category: category.slug
        }
      };
    },
    subjectViewRoute({ category, subject }) {
      return {
        name: "subject.view",
        params: {
          category: category.slug,
          subject: subject.slug
        }
      };
    }
  },
  watch: {
    page: "changePage",
    loaded: {
      handler(value) {
        if (value === true) {
          this.search();
        }
      },
      immediate: true
    }
  }
};
</script>
