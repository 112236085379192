<template>
  <b-card-body class="document-preview" bg-variant="dark" text-variant="white">
    <b-img-lazy
      v-for="page in doc.pages"
      :key="page.number"
      :src="page.previewPath"
      :alt="page.content"
      blank-color="#FFF"
      class="document-page"
      ref="image"
    />
  </b-card-body>
</template>

<script>
export default {
  name: "DocumentPreview",
  props: {
    doc: {
      type: Object,
      default() {
        return {
          title: "",
          pages: []
        };
      }
    }
  },
  methods: {
    onPageLoaded(e) {
      const target = e.target;
      const { naturalWidth: width, naturalHeight: height } = target;

      if (height < width && !target.classList.contains("album")) {
        target.classList.add("album");
      } else if (height > width && target.classList.contains("album")) {
        target.classList.add("album");
      }
    }
  },
  mounted() {
    this.$refs.image.map(img => {
      img.$el.onload = this.onPageLoaded;
    });
  }
};
</script>
