<template>
  <section class="page">
    <header>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <router-link :to="{ name: 'home' }">
              <img src="/assets/img/logo.png" alt="logo" />
            </router-link>
          </div>
        </div>
      </div>
    </header>
    <main class="container">
      <div class="row">
        <div class="col-lg-12">
          <div id="rtb-top"></div>
        </div>
        <aside class="col-lg-3">
          <UserPanel />
          <CategoriesNavPanel />
          <div id="rtb-side"></div>
        </aside>
        <div class="col-lg-9">
          <SearchBar placeholder="Nimani qidiramiz?" />
          <Breadcrumbs :items="breadcrumbs" />
          <slot />
        </div>
      </div>
    </main>
    <app-footer />
  </section>
</template>

<script>
import UserPanel from "../components/UserPanel.vue";
import CategoriesNavPanel from "../components/CategoriesNavPanel.vue";
import Breadcrumbs from "../components/Breadcrumbs.vue";
import AppFooter from "@/components/AppFooter";
import YandexSearchBar from "@/components/YandexSearchBar";

export default {
  name: "default",
  components: {
    UserPanel,
    CategoriesNavPanel,
    SearchBar: YandexSearchBar,
    Breadcrumbs,
    AppFooter
  },
  computed: {
    breadcrumbs() {
      const breadcrumbs = [
        {
          text: this.$t("home"),
          to: `/${this.$route.params.locale}`
        }
      ];

      return breadcrumbs.concat(this.$attrs.breadcrumbs || []);
    }
  },
  mounted() {
    window.pageNumber = window.pageNumber || 1;
    this.$nextTick(() => {
      (function(w, d, n, s, t) {
        w[n] = w[n] || [];
        w[n].push(function() {
          w.Ya.Context.AdvManager.render({
            blockId: "R-A-525981-2",
            renderTo: "rtb-top",
            async: true,
            pageNumber: w.pageNumber,
            onRender: function(data) {
              w.console.log(`Наверху показывается: ${data.product}`);
              d.getElementById("rtb-top").style.marginBottom = "25px";
            }
          });
          w.Ya.Context.AdvManager.render({
            blockId: "R-A-525981-1",
            renderTo: "rtb-side",
            async: true,
            pageNumber: w.pageNumber,
            onRender: function(data) {
              w.console.log(`С боку показывается: ${data.product}`);
            }
          });
        });
        t = d.getElementsByTagName("script")[0];
        s = d.createElement("script");
        s.type = "text/javascript";
        s.src = "//an.yandex.ru/system/context.js";
        t.parentNode.insertBefore(s, t);
      })(window, window.document, "yandexContextAsyncCallbacks");
      window.pageNumber++;
    });
  }
};
</script>
