class Strings {
  static concatIf(condition, text, concatenation) {
    if (!concatenation) {
      return text;
    }

    return text + concatenation;
  }
}

export default Strings;
