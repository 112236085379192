<template>
  <layout :breadcrumbs="breadcrumbs">
    <section class="subject-view" ref="localRoot">
      <loading
        :active="loading"
        v-if="loading"
        :is-full-page="false"
        :container="$refs.localRoot"
        :width="50"
        :height="50"
        color="#ff686b"
      />

      <b-card v-for="doc in docs" :key="doc.id" class="document">
        <b-card-title title-tag="h6" class="m-0">
          <i
            class="icofont-2x mr-2"
            :class="
              fileExtensionIcon(doc.metadata.file.extension.toLowerCase())
            "
          ></i>
          <b-link :to="documentViewRoute(doc)">{{ doc.title }}</b-link>
        </b-card-title>
      </b-card>

      <b-pagination
        v-if="isPaginationNeeded"
        v-model="page"
        :total-rows="total"
        :per-page="pageSize"
        :first-text="$t('pagination.first')"
        :prev-text="$t('pagination.prev')"
        :next-text="$t('pagination.next')"
        :last-text="$t('pagination.last')"
        align="center"
      />
    </section>
  </layout>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Loading from "vue-loading-overlay";
import axios from "axios";
import Layout from "@/layouts/Default";
import Routing from "@/constants/routing";

export default {
  name: "subject",
  components: {
    Layout,
    Loading
  },
  data() {
    return {
      loading: true,
      docs: [],
      total: 0,
      pageSize: this.$route.query.pageSize || 10,
      page: this.$route.query.page || 1
    };
  },
  computed: {
    ...mapState({
      loaded: state => state.subjects.loaded,
      active: state => state.subjects.active,
      category: state => state.categories.active
    }),
    breadcrumbs() {
      if (!this.active) {
        return [];
      }

      const pre = [];

      if (this.category) {
        pre.push({
          text: this.category.name,
          to: {
            name: "category.view",
            params: {
              category: this.category.slug
            }
          }
        });
      }

      pre.push({
        text: this.active.name,
        to: {
          name: "subject.view",
          params: {
            category:
              (this.category && this.category.slug) || Routing.category.default,
            subject: this.active.slug
          }
        }
      });
      return pre;
    },
    isPaginationNeeded() {
      return !this.isLoading && this.total > this.pageSize;
    }
  },
  methods: {
    ...mapMutations("subjects", ["markAsActiveWithSlug", "unsetActive"]),
    ...mapMutations("categories", {
      markActiveCategory: "markAsActiveWithSlug",
      unsetActiveCategory: "unsetActive"
    }),
    async fetchDocuments() {
      try {
        const { category, subject } = this.$route.params;
        const { pageSize, page } = this;
        const response = await axios.get(
          `/documents/${category}/${subject}?page=${page}&pageSize=${pageSize}`
        );
        this.docs = response.data.documents;
        this.total = response.data.total;
      } finally {
        this.loading = false;
      }
    },
    documentViewRoute(doc) {
      return {
        name: "document.view",
        params: {
          category: doc.category.slug,
          subject: doc.subject.slug,
          document: doc.slug
        }
      };
    },
    changePage() {
      this.$router.replace({
        name: "subject.view",
        params: {
          category: this.category.slug,
          subject: this.active.slug
        },
        query: {
          pageSize: this.pageSize,
          page: this.page
        }
      });
    },
    fileExtensionIcon(ext) {
      if (["doc", "docx"].includes(ext)) {
        return "icofont-file-word";
      }

      if (["ppt", "pptx"].includes(ext)) {
        return "icofont-file-powerpoint";
      }

      if (ext === "pdf") {
        return "icofont-file-pdf";
      }

      return "icofont-file-document";
    }
  },
  mounted() {
    this.fetchDocuments();
  },
  watch: {
    loaded: {
      handler(value) {
        if (!value) {
          return;
        }

        if (this.$route.params.category) {
          this.markActiveCategory(this.$route.params.category);
        }

        this.markAsActiveWithSlug(this.$route.params.subject);
        document.title = this.active.name;
      },
      immediate: true
    },
    page: "fetchDocuments"
  },
  beforeRouteLeave(to, from, next) {
    this.unsetActiveCategory();
    this.unsetActive();
    next();
  }
};
</script>
