<template>
  <layout :breadcrumbs="breadcrumbs">
    <div
      id="ya-site-results"
      data-bem='{"tld": "ru","language": "ru","encoding": ""}'
    ></div>
  </layout>
</template>

<script>
import Layout from "../layouts/Default";

export default {
  name: "YandexSearch",
  components: {
    Layout
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: this.$t("search.title"),
          path: this.$route.path
        }
      ];
    }
  }
};
</script>

<style scoped></style>
