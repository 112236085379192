<template>
  <layout :breadcrumbs="breadcrumbs">
    <b-card
      class="user-registration"
      :title="$t('registration.title')"
      ref="card"
    >
      <loading
        :active="loading"
        :is-full-page="false"
        :container="$refs.card"
        :width="40"
        :height="40"
        color="#ff686b"
      />
      <user-registration-form v-if="!loading" />
    </b-card>
  </layout>
</template>

<script>
import UserRegistrationForm from "../components/UserRegistrationForm";
import Layout from "../layouts/Default";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";

export default {
  name: "UserRegistration",
  components: {
    UserRegistrationForm,
    Layout,
    Loading
  },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    ...mapGetters("user", ["isAuthorized", "isLoaded"]),
    breadcrumbs() {
      return [
        {
          text: this.$t("registration.title"),
          to: `/${this.$i18n.locale}/user/registration`
        }
      ];
    }
  },
  watch: {
    isLoaded: {
      handler(value) {
        if (value === true && this.isAuthorized) {
          this.$router.push(`/${this.$route.params.locale}`);
        }

        this.loading = !value;
      },
      immediate: true
    }
  }
};
</script>
