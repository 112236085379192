<template>
  <layout :breadcrumbs="breadcrumbs" style="position: relative;">
    <section class="user-subscriptions">
      <loading
        :active="loading"
        :is-full-page="false"
        :container="$refs.localRoot"
        :width="40"
        :height="40"
        color="#394757"
      />
      <subscription-plans v-if="notSubscribed" :plans="plans" />
      <b-alert v-if="isSubscribed" show variant="info" dismissible>
        {{ $t("subscription.isActiveMessage") }}
      </b-alert>
      <subscription-history v-if="hasHistory" :subscriptions="subscriptions" />
      <b-alert :show="notSubscribedEver" variant="info">
        {{ $t("subscription.noPurchasesYet") }}
      </b-alert>
    </section>
  </layout>
</template>

<script>
import Layout from "../layouts/Default";
import SubscriptionPlans from "@/components/SubscriptionPlans";
import SubscriptionHistory from "@/components/SubscriptionHistory";
import SubscriptionStatus from "@/constants/subscription-statuses";
import axios from "axios";
import Loading from "vue-loading-overlay";

export default {
  name: "UserSubscriptions",
  components: {
    Layout,
    SubscriptionPlans,
    SubscriptionHistory,
    Loading
  },
  data() {
    return {
      loading: true,
      plans: [],
      subscriptions: []
    };
  },
  computed: {
    breadcrumbs() {
      return [
        { text: this.$t("subscription.title"), to: "/user/subscriptions" }
      ];
    },
    hasHistory() {
      return !this.loading && this.subscriptions.length;
    },
    notSubscribedEver() {
      return !this.loading && !this.hasHistory;
    },
    isSubscribed() {
      return (
        !this.loading &&
        this.subscriptions.filter(
          ({ status }) => SubscriptionStatus.ACTIVATED === status
        ).length
      );
    },
    notSubscribed() {
      return !this.loading && !this.isSubscribed;
    }
  },
  methods: {
    async getPlans() {
      const response = await axios.get("/subscription/plans");
      return response.data;
    },
    async getSubscriptions() {
      const response = await axios.get(`/user/subscriptions`);
      return response.data;
    }
  },
  async created() {
    const [plans, subscriptions] = await Promise.all([
      this.getPlans(),
      this.getSubscriptions()
    ]);

    this.plans = plans;
    this.subscriptions = subscriptions;
    this.loading = false;
  }
};
</script>

<style scoped></style>
