<template>
  <layout :breadcrumbs="breadcrumbs">
    <section class="category-view">
      <loading
        :active="!loaded"
        :is-full-page="false"
        :width="50"
        :height="50"
        color="#ff686b"
      />
      <component v-if="isComponentNeeded" :is="component" />
      <router-view v-if="isRouteViewNeeded" />
    </section>
  </layout>
</template>

<script>
import Layout from "../layouts/Default";
import Loading from "vue-loading-overlay";
import { mapState, mapMutations } from "vuex";
import Subject from "@/views/Subject";
import CategoryDefault from "@/views/CategoryDefault";

export default {
  name: "category",
  components: {
    Layout,
    Loading,
    Subject,
    CategoryDefault
  },
  computed: {
    ...mapState("categories", {
      loaded: state => state.loaded,
      active: state => state.active
    }),
    breadcrumbs() {
      if (!this.loaded) {
        return [];
      }

      return [
        {
          text: this.active.name,
          to: {
            name: "category.view",
            params: { category: this.active.slug }
          }
        }
      ];
    },
    isRouteViewNeeded() {
      return this.loaded && this.$route.name !== "category.view";
    },
    isComponentNeeded() {
      return this.loaded && this.$route.name === "category.view";
    },
    component() {
      if (!this.active && this.$route.params.category) {
        return "subject";
      }

      return "category-default";
    }
  },
  methods: {
    ...mapMutations("categories", ["markAsActiveWithSlug"])
  },
  watch: {
    loaded: {
      handler(value) {
        if (!value) {
          return;
        }

        this.markAsActiveWithSlug(this.$route.params.category);

        if (this.active) {
          document.title = this.active.name;
        }
      },
      immediate: true
    }
  }
};
</script>
