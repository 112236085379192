<template>
  <default-layout>
    <subjects-nav />
    <recent-documents-list />
  </default-layout>
</template>

<script>
import SubjectsNav from "../components/SubjectsNav";
import RecentDocumentsList from "../components/RecentDocumentsList";
import DefaultLayout from "../layouts/Default";
import { mapMutations } from "vuex";

export default {
  name: "home",
  components: {
    SubjectsNav,
    RecentDocumentsList,
    DefaultLayout
  },
  methods: {
    ...mapMutations("categories", {
      unsetActiveCategory: "unsetActive"
    })
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.unsetActiveCategory());
  }
};
</script>
