import axios from "axios";

export const state = {
  all: [],
  loaded: false
};

export const mutations = {
  save: (state, subjects) => {
    state.all = subjects;
  },
  markAsLoaded: state => {
    state.loaded = true;
  }
};

export const actions = {
  async __init({ commit }) {
    try {
      const locale = location.pathname.split("/").filter(x => !!x)[0];
      const response = await axios.get(`/${locale}/links/footer`);
      commit("save", response.data);
      commit("markAsLoaded");
    } finally {
      // do something
    }
  }
};
