<template>
  <b-card class="categories-nav-panel" no-body>
    <b-card-body class="d-block d-sm-none">
      <b-card-title>{{ $t("categories.title") }}</b-card-title>
      <b-form-select
        v-model="selected"
        :options="mobileSelectOptions"
        ref="mobileCategorySelect"
      >
        <template slot="first">
          <option :value="null" disabled>{{ $t("categories.all") }}</option>
        </template>
      </b-form-select>
    </b-card-body>
    <b-list-group flush ref="list" class="d-none d-sm-block">
      <loading
        v-if="!loaded"
        :active="true"
        :is-full-page="false"
        :container="$refs.list"
        :width="40"
        :height="40"
        color="#ff686b"
      />
      <!-- categories list -->
      <b-list-group-item
        v-for="category in all"
        :to="categoryRoute(category)"
        :active="isActive(category)"
        :key="category.id"
        class="category-nav-item"
      >
        {{ category.name }}
        <b-badge variant="secondary" pill>
          {{ category.documentsCount }}
        </b-badge>
      </b-list-group-item>
    </b-list-group>
    <div slot="footer" class="d-none d-sm-block">
      <!-- default, all categories -->
      <b-link :to="{ name: 'home' }" block>{{ $t("categories.all") }}</b-link>
    </div>
  </b-card>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Loading from "vue-loading-overlay";
import Routing from "@/constants/routing";

export default {
  name: "CategoriesNavPanel",
  components: {
    Loading
  },
  data() {
    return {
      selected: null
    };
  },
  computed: {
    ...mapState("categories", {
      all: state => state.all,
      active: state => state.active,
      loaded: state => state.loaded
    }),
    mobileSelectOptions() {
      return this.all.map(c => {
        return { value: c.slug, text: c.name };
      });
    }
  },
  methods: {
    ...mapMutations("categories", ["markAsActive", "markAsActiveWithSlug"]),
    isActive(category) {
      return !!(this.active && this.active.id === category.id);
    },
    categoryRoute({ slug }) {
      return {
        name: "category.view",
        params: {
          locale: this.$i18n.locale,
          category: slug || Routing.category.default
        }
      };
    }
  },
  watch: {
    selected: {
      handler(value) {
        if (value) {
          this.$router.push({
            name: "category.view",
            params: {
              locale: this.$i18n.locale,
              category: value
            }
          });
          return;
        }

        this.$router.push({
          name: "home",
          params: {
            locale: this.$i18n.locale
          }
        });
      }
    }
  }
};
</script>
