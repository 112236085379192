<template>
  <layout>
    <b-card bg-variant="warning">
      <b-card-title title-tag="h1" class="text-center">
        <i class="icofont-warning-alt"></i>
      </b-card-title>
      <b-card-title title-tag="h5" class="text-center">
        {{ $t("notFound.message") }}
      </b-card-title>
    </b-card>
  </layout>
</template>

<script>
import Layout from "../layouts/Default";

export default {
  name: "NotFound",
  components: { Layout },
  data() {
    return {
      status: 404
    };
  }
};
</script>
