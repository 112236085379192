<template>
  <b-card
    class="user-panel"
    :class="{ loading: loading, unauthorized: !isAuthorized }"
    border-variant="primary"
    ref="card"
    no-body
  >
    <loading
      :active="loading"
      :is-full-page="false"
      :container="$refs.card"
      :width="40"
      :height="40"
      color="#ff686b"
    />

    <b-card-header
      v-if="isAuthorized"
      header-bg-variant="primary"
      header-text-variant="white"
      align="center"
    >
      <i class="icofont-user-alt-7 pr-2"></i>
      <span class="align-middle">{{ user.name }}</span>
    </b-card-header>

    <!-- if user is authorized show actions -->
    <b-list-group v-if="isAuthorized" class="user-actions" flush>
      <b-list-group-item
        v-if="isLocalUser"
        class="user-action"
        :to="{ name: 'user.settings' }"
      >
        <i class="icofont-settings"></i>
        {{ $t("user.settings.panel.title") }}
      </b-list-group-item>
      <b-list-group-item
        class="user-action"
        :to="{ name: 'user.subscriptions' }"
        v-if="isUser"
      >
        <i class="icofont-pay"></i>
        {{ $t("user.subscriptions") }}
      </b-list-group-item>
      <b-list-group-item
        class="user-action"
        href="/admin"
        v-if="hasAdminPermission"
      >
        <i class="icofont-dashboard-web"></i>
        {{ $t("user.administrate") }}
      </b-list-group-item>
      <b-list-group-item class="user-action" button @click="logout">
        <i class="icofont-exit"></i>
        {{ $t("user.logOut") }}
      </b-list-group-item>
    </b-list-group>

    <!-- user login form -->
    <user-panel-form v-else-if="isLoaded && !isAuthorized" />
  </b-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import UserPanelForm from "./UserPanelForm";
import Loading from "vue-loading-overlay";

export default {
  name: "UserPanel",
  components: {
    UserPanelForm,
    Loading
  },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["isAuthorized", "isLoaded"]),
    isUser() {
      return this.user.role === "ordinary";
    },
    hasAdminPermission() {
      return this.user.role !== "ordinary";
    },
    isLocalUser() {
      return this.user.authentication === "local";
    }
  },
  methods: {
    ...mapActions("user", { $logout: "logout" }),
    async logout() {
      this.loading = true;
      await this.$logout();
      this.loading = false;
      this.$router.push(`/${this.$route.params.locale}`);
    }
  },
  watch: {
    isLoaded: {
      handler(value) {
        this.loading = !value;
      },
      immediate: true
    }
  }
};
</script>
