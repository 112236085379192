<template>
  <section class="search-bar" @click.stop.prevent ref="bar">
    <form
      :action="`/${$i18n.locale}/search/yandex`"
      method="get"
      target="_self"
      accept-charset="utf-8"
      ref="form"
    >
      <input type="hidden" name="searchid" value="2362470" />
      <input type="hidden" name="l10n" value="ru" />
      <input type="hidden" name="reqenc" value="utf-8" />
      <b-input-group size="lg">
        <input
          v-model="query"
          autofocus
          type="text"
          name="text"
          :placeholder="$t('search.barPlaceholder')"
          class="search-input form-control"
          @keydown.enter.prevent="onEnter"
          v-bind="$attrs"
        />
        <b-input-group-append>
          <b-btn variant="primary" :disabled="$v.$invalid" @click="goFullPage">
            <i class="icofont-search"></i>
          </b-btn>
        </b-input-group-append>
      </b-input-group>
    </form>
  </section>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "YandexSearchBar",
  data() {
    return {
      query: this.$route.query.query || ""
    };
  },
  computed: {
    isValid() {
      this.$v.query.$touch();
      return !this.$v.query.$invalid;
    }
  },
  validations: {
    query: {
      required,
      minLength: minLength(3)
    }
  },
  methods: {
    goFullPage() {
      this.$refs.form.submit();
    },
    onEnter() {
      if (this.isValid) {
        this.goFullPage();
      }
    }
  }
};
</script>
