<template>
  <b-form ref="form" @submit.stop.prevent no-validate>
    <b-form-group
      :label="$t('user.settings.form.fullName.label')"
      :state="toValidityState($v.name)"
      :description="$t('user.settings.form.fullName.description')"
      :invalid-feedback="$t('user.settings.form.fullName.invalid')"
    >
      <!-- name field -->
      <b-form-input
        v-model="name"
        name="name"
        :state="toValidityState($v.name)"
        @blur="$v.name.$touch"
        trim
        :disabled="submitting"
        :placeholder="$t('user.settings.form.fullName.placeholder')"
      />
    </b-form-group>

    <!-- Password field -->
    <b-form-group
      :label="$t('user.settings.form.password.label')"
      :state="toValidityState($v.password)"
      :description="$t('user.settings.form.password.description')"
      :invalid-feedback="$t('user.settings.form.password.invalid')"
    >
      <b-form-input
        v-model="password"
        name="password"
        :state="toValidityState($v.password)"
        @blur="onPasswordChange"
        trim
        type="password"
        :disabled="submitting"
        :placeholder="$t('user.settings.form.password.placeholder')"
      />
    </b-form-group>
    <b-form-group
      :state="passwordCheckState"
      :invalid-feedback="$t('user.settings.form.passwordCheck.invalid')"
    >
      <b-form-input
        v-model="passwordReenter"
        name="passwordReenter"
        :state="passwordCheckState"
        trim
        type="password"
        :disabled="submitting"
        :placeholder="$t('user.settings.form.passwordCheck.placeholder')"
      />
    </b-form-group>

    <b-button variant="primary" @click="submit" :disabled="isButtonDisabled">
      <b-spinner v-if="submitting" small class="mr-2"></b-spinner>
      <span v-t="'user.settings.form.submit'"></span>
    </b-button>
  </b-form>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  name: "UserSettingsForm",
  props: {
    user: {
      type: Object,
      default() {
        return {
          name: ""
        };
      }
    }
  },
  data() {
    return {
      name: "",
      password: "",
      passwordReenter: "",
      submitting: false
    };
  },
  computed: {
    passwordCheckState() {
      if (this.password.length === 0) {
        return null;
      }

      return !this.$v.passwordReenter.$invalid;
    },
    isButtonDisabled() {
      return this.submitting || this.$v.$invalid;
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(3)
    },
    password: {
      minLength: minLength(8)
    },
    passwordReenter: {
      sameAsPassword: sameAs("password")
    },
    passwords: ["password", "passwordReenter"]
  },
  methods: {
    ...mapActions("user", ["changeUserSettings"]),
    toValidityState(validator) {
      if (!validator.$dirty || validator.$model.length === 0) {
        return null;
      }

      return !validator.$invalid;
    },
    onPasswordChange() {
      this.$v.password.$touch();
      this.$v.passwordReenter.$touch();
    },
    async submit() {
      if (this.$v.$invalid) {
        return;
      }

      try {
        this.submitting = true;
        await this.changeUserSettings({
          name: this.name,
          password: this.password
        });
        this.$bvModal.msgBoxOk(
          "Sozlamalaringiz o'zgardi. Yangi sozlamalar qayta kirganingizda amal qila boshlaydi.",
          {
            size: "sm",
            okVariant: "success",
            headerClass: "p-2 border-bottom-0",
            footerClass: "p-2 border-top-0",
            centered: true
          }
        );
      } catch (e) {
        this.$bvModal.msgBoxOk("Xatolik yuz berdi", {
          size: "sm",
          okVariant: "danger",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true
        });
      } finally {
        this.submitting = false;
        this.password = "";
        this.passwordReenter = "";
      }
    }
  },
  watch: {
    user: {
      handler() {
        this.name = this.user.name;
      },
      immediate: true
    }
  }
};
</script>

<style scoped></style>
