<template>
  <layout :breadcrumbs="breadcrumbs">
    <b-card class="user-settings" :title="$t('user.settings.title')">
      <div v-if="loading" class="loading-placeholder">
        <b-spinner variant="primary" />
      </div>
      <user-settings-form v-else :user="user" />
    </b-card>
  </layout>
</template>

<script>
import Layout from "../layouts/Default";
import UserSettingsForm from "../components/UserSettingsForm";
import { mapState, mapGetters } from "vuex";

export default {
  name: "UserSettings",
  components: {
    Layout,
    UserSettingsForm
  },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["isLoaded", "isAuthorized"]),
    breadcrumbs() {
      return [{ text: this.$t("user.settings.title"), to: "/user/settings" }];
    }
  },
  watch: {
    isLoaded: {
      handler(value) {
        if (value === true && !this.isAuthorized) {
          this.$router.push({ name: "not.found" });
        }

        this.loading = !value;
      },
      immediate: true
    }
  }
};
</script>

<style scoped></style>
