<template>
  <b-card class="subjects-navigation" ref="localRoot" no-body>
    <loading
      :active.sync="isLoading"
      :is-full-page="false"
      :container="$refs.localRoot"
      :width="40"
      :height="40"
      color="#394757"
    />
    <b-card-body class="d-block d-sm-none">
      <b-form-select
        v-model="selected"
        :options="mobileSelectOptions"
        ref="mobileCategorySelect"
      >
        <template slot="first">
          <option :value="null" disabled>{{ $t("subjects.title") }}</option>
        </template>
      </b-form-select>
    </b-card-body>
    <b-card-body class="d-none d-sm-block">
      <b-row>
        <b-col
          v-for="subject in subjects"
          :key="subject.id"
          md="4"
          sm="6"
          class="mb-1"
        >
          <b-link :to="subjectRoute(subject)" :title="subject.name">
            <i class="icofont-library mr-2"></i>
            <span>{{ subject.name }}</span>
          </b-link>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapState } from "vuex";
import Loading from "vue-loading-overlay";
import Routing from "@/constants/routing";

export default {
  name: "SubjectsNav",
  components: {
    Loading
  },
  data() {
    return {
      selected: null
    };
  },
  computed: {
    ...mapState("subjects", {
      subjects: state => state.all,
      loaded: state => state.loaded
    }),
    ...mapState("categories", {
      currentCategory: state => state.active && state.active.slug
    }),
    isLoading() {
      return !this.loaded;
    },
    mobileSelectOptions() {
      return this.subjects.map(s => {
        return { value: s.slug, text: s.name };
      });
    }
  },
  methods: {
    subjectRoute({ slug }) {
      return {
        name: "subject.view",
        params: {
          category: this.currentCategory || Routing.category.default,
          subject: slug
        }
      };
    }
  },
  watch: {
    selected: {
      handler(value) {
        if (value) {
          this.$router.push({
            name: "subject.view",
            params: {
              locale: this.$i18n.locale,
              category: this.currentCategory || Routing.category.default,
              subject: value
            }
          });
          return;
        }

        if (this.currentCategory) {
          this.$router.push({
            name: "category.view",
            params: {
              locale: this.$i18n.locale,
              category: this.currentCategory
            }
          });
          return;
        }

        this.$router.push({
          name: "home",
          params: {
            locale: this.$i18n.locale
          }
        });
      }
    }
  }
};
</script>
