<template>
  <section class="subscription-plans">
    <h4>{{ $t("subscription.purchase") }}</h4>
    <b-card-group deck>
      <subscription-plan v-for="plan in plans" :key="plan.id" :plan="plan" />
    </b-card-group>
  </section>
</template>

<script>
import SubscriptionPlan from "@/components/SubscriptionPlan";

export default {
  name: "SubscriptionPlans",
  components: { SubscriptionPlan },
  props: {
    plans: {
      type: Array,
      default() {
        return [];
      }
    }
  }
};
</script>
