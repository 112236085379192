<template>
  <b-list-group-item
    class="subscription"
    :class="{ activated: isActive, cancelled: isCancelled }"
  >
    <time class="time small" :datetime="subscription.createdAt">
      {{ subscription.createdAt | moment("from", "now") }}
    </time>
    <div class="info">
      <h3 class="subscription-plan-name">
        <span v-if="subscription.plan">{{ subscription.plan.name }}</span>
        <span v-else>Sovg'a</span>
      </h3>
      <div v-if="isPlanPurchase">
        <div class="payment-method" :class="payment"></div>
      </div>
      <h4 v-if="isPlanPurchase" class="text-right">
        {{ subscription.plan.price }} <br />
        <small>{{ $t("subscription.money.uzs") }}</small>
      </h4>
      <div v-else>
        <i class="icofont-gift icofont-2x"></i>
      </div>
    </div>
    <div v-if="!isCancelled" class="small">
      {{ interval }}
    </div>
    <div v-if="isCancelled">
      <small>
        {{ $t("subscription.cancelledAtMessage") }}:
        {{ subscription.cancelledAt | moment("from", "now") }}
      </small>
    </div>
  </b-list-group-item>
</template>

<script>
import SubscriptionType from "@/constants/subscription-types";
import SubscriptionStatus from "@/constants/subscription-statuses";
import PaymentMethod from "@/constants/payment-methods";

export default {
  name: "SubscriptionsHistoryItem",
  props: {
    subscription: {
      type: Object,
      default() {
        return {
          createdAt: new Date(),
          activatedAt: new Date()
        };
      }
    }
  },
  computed: {
    isPlanPurchase() {
      return this.subscription.type === SubscriptionType.PLAN_PURCHASE;
    },
    isActive() {
      return this.subscription.status === SubscriptionStatus.ACTIVATED;
    },
    isCancelled() {
      return this.subscription.status === SubscriptionStatus.CANCELLED;
    },
    payment() {
      const payment = {};

      Object.keys(PaymentMethod).forEach(method => {
        payment[method.toLowerCase()] =
          PaymentMethod[method] === this.subscription.paymentMethod;
      });

      return payment;
    },
    interval() {
      return this.$t("subscription.interval", {
        from: this.$moment(this.subscription.activatedAt).format("DD.MM.YYYY"),
        to: this.$moment(this.subscription.expiresAt).format("DD.MM.YYYY")
      });
    }
  }
};
</script>
