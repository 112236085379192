<template>
  <b-form ref="form" class="user-panel-form" @submit.stop.prevent novalidate>
    <!-- username & password input groups -->
    <b-form-group>
      <b-form-input
        v-model="username"
        required
        :placeholder="$t('user.username')"
        :state="toValidityState($v.username)"
        :disabled="loading"
      />
    </b-form-group>
    <b-form-group>
      <b-form-input
        v-model="password"
        type="password"
        required
        :placeholder="$t('user.password')"
        :state="toValidityState($v.password)"
        :disabled="loading"
      />
    </b-form-group>
    <!-- login button -->
    <b-button type="submit" @click="login" variant="primary" size="sm" block>
      <b-spinner v-if="loading" small></b-spinner>
      <span v-if="!loading">{{ $t("user.logIn") }}</span>
    </b-button>
    <!-- register button -->
    <div class="text-center">
      <small class="mr-1">{{ $t("or") }}</small>
      <b-link :to="{ name: 'user.registration' }" class="small">
        {{ $t("user.register") }}
      </b-link>
    </div>
    <!-- other login options -->
    <hr class="mt-2 mb-2" />
    <b-button size="sm" href="/login/facebook" class="btn-facebook-login" block>
      <i class="icofont-facebook"></i>
      <span>{{ $t("user.facebook.logIn") }}</span>
    </b-button>
  </b-form>
</template>

<script>
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "UserPanelForm",
  data() {
    return {
      username: "",
      password: "",
      loading: false
    };
  },
  computed: {
    canBeSubmitted() {
      return !this.loading || !this.$v.$invalid;
    }
  },
  validations: {
    username: {
      required
    },
    password: {
      required
    }
  },
  methods: {
    ...mapActions("user", { $login: "login" }),
    async login() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      try {
        this.loading = true;
        await this.$login({ username: this.username, password: this.password });
      } catch (e) {
        if (e.response) {
          this.informInvalidLogin();
          return;
        }
        // not login error
        throw e;
      } finally {
        this.clear();
        this.loading = false;
      }
    },
    clear() {
      this.username = "";
      this.password = "";
    },
    informInvalidLogin() {
      this.$bvModal.msgBoxOk(this.$t("user.invalid.login"), {
        size: "sm",
        okVariant: "danger",
        headerClass: "p-2 border-bottom-0",
        footerClass: "p-2 border-top-0",
        centered: true
      });
    },
    toValidityState(validator) {
      if (!validator.$dirty || !validator.$invalid) {
        return null;
      }

      return false;
    }
  }
};
</script>
