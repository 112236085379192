<template>
  <section>
    <subjects-nav />
    <recent-documents-list />
  </section>
</template>

<script>
import SubjectsNav from "../components/SubjectsNav";
import RecentDocumentsList from "../components/RecentDocumentsList";

export default {
  name: "CategoryDefault",
  components: {
    SubjectsNav,
    RecentDocumentsList
  }
};
</script>
