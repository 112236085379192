<template>
  <b-card class="subscriptions-history" no-body>
    <b-card-body>
      <b-card-title class="mb-0">
        {{ $t("subscription.history") }}
      </b-card-title>
    </b-card-body>
    <b-list-group class="history" flush>
      <subscriptions-history-item
        v-for="subscription in subscriptions"
        :key="subscription.id"
        :subscription="subscription"
      />
    </b-list-group>
  </b-card>
</template>

<script>
import SubscriptionsHistoryItem from "@/components/SubscriptionsHistoryItem";

export default {
  name: "SubscriptionHistory",
  components: { SubscriptionsHistoryItem },
  props: {
    subscriptions: {
      type: Array,
      default() {
        return [];
      }
    }
  }
};
</script>
