<!--suppress JSCheckFunctionSignatures -->
<template>
  <b-form @submit.stop.prevent ref="form" no-validate>
    <b-form-group
      :label="labels.name"
      :state="toValidityState($v.name)"
      :invalid-feedback="$t('registration.form.fullName.invalid')"
    >
      <!-- name field -->
      <b-form-input
        v-model="name"
        name="name"
        :state="toValidityState($v.name)"
        @blur="$v.name.$touch"
        trim
        tabindex="1"
        autofocus
        :disabled="submitting"
        :placeholder="$t('registration.form.fullName.placeholder')"
      />
    </b-form-group>
    <!-- username/login field -->
    <b-form-group
      :label="labels.username"
      :state="toValidityState($v.username)"
      :description="$t('registration.form.username.description')"
    >
      <b-form-input
        v-model="username"
        name="username"
        :state="toValidityState($v.username)"
        @blur="$v.username.$touch"
        trim
        tabindex="2"
        :disabled="submitting"
        :placeholder="$t('registration.form.username.placeholder')"
      />
      <b-form-invalid-feedback v-if="!$v.username.minLength">
        {{ $t("registration.form.username.invalid.length") }}
      </b-form-invalid-feedback>
      <b-form-invalid-feedback v-if="!$v.username.mustBeUnique">
        {{ $t("user.invalid.duplicateUsername") }}
      </b-form-invalid-feedback>
    </b-form-group>
    <!-- Password field -->
    <b-form-group
      :label="labels.password"
      :state="toValidityState($v.password)"
      :description="$t('registration.form.password.description')"
      :invalid-feedback="$t('registration.form.password.invalid')"
    >
      <b-form-input
        v-model="password"
        name="password"
        :state="toValidityState($v.password)"
        @blur="$v.password.$touch"
        trim
        type="password"
        tabindex="3"
        :disabled="submitting"
        :placeholder="$t('registration.form.password.placeholder')"
      />
    </b-form-group>
    <b-form-group
      :state="toValidityState($v.passwordReenter)"
      :invalid-feedback="$t('registration.form.passwordReenter.invalid')"
    >
      <b-form-input
        v-model="passwordReenter"
        name="passwordReenter"
        :state="toValidityState($v.passwordReenter)"
        @blur="$v.passwordReenter.$touch"
        trim
        type="password"
        tabindex="4"
        :disabled="submitting"
        :placeholder="$t('registration.form.passwordReenter.placeholder')"
      />
    </b-form-group>
    <span class="small mb-2 mt-2 float-right text-muted">
      * - {{ $t("registration.form.requiredFieldsMessage") }}
    </span>
    <vue-recaptcha
      ref="recaptcha"
      size="invisible"
      @verify="submit"
      :sitekey="sitekey"
      :loadRecaptchaScript="true"
    >
      <b-button variant="primary" :disabled="isButtonDisabled" tabindex="5">
        <b-spinner v-if="submitting" small class="mr-2"></b-spinner>
        <span>{{ $t("registration.form.register") }}</span>
      </b-button>
    </vue-recaptcha>
  </b-form>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import VueRecaptcha from "vue-recaptcha";
import Strings from "../utils/strings";
import axios from "axios";
import { mapActions } from "vuex";

export default {
  name: "UserRegistrationForm",
  components: { VueRecaptcha },
  data() {
    return {
      name: "",
      username: "",
      password: "",
      passwordReenter: "",
      submitting: false,
      duplicateUsernames: []
    };
  },
  computed: {
    labels() {
      return {
        name: Strings.concatIf(
          this.$v.name.required,
          this.$t("registration.form.fullName.label"),
          " *"
        ),
        username: Strings.concatIf(
          this.$v.username.required,
          this.$t("registration.form.username.label"),
          " *"
        ),
        password: Strings.concatIf(
          this.$v.password.required,
          this.$t("registration.form.password.label"),
          " *"
        )
      };
    },
    sitekey() {
      return process.env.VUE_APP_RECAPTCHA_SITE_KEY;
    },
    isButtonDisabled() {
      return this.submitting || this.$v.$invalid;
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(3)
    },
    username: {
      required,
      minLength: minLength(5),
      mustBeUnique: (value, vm) => !vm.duplicateUsernames.includes(value)
    },
    password: {
      required,
      minLength: minLength(5)
    },
    passwordReenter: {
      required,
      sameAsPassword: sameAs("password")
    },
    passwords: ["password", "passwordReenter"]
  },
  methods: {
    ...mapActions("user", ["login"]),
    async submit() {
      if (this.$v.$invalid) {
        return;
      }

      try {
        this.beforeSubmit();
        let data = new FormData(this.$refs.form);
        let config = { headers: { "content-type": "multipart/form-data" } };
        await this.onSubmitSuccess(
          await axios.post("/user/register", data, config)
        );
      } catch (e) {
        this.onSubmitError(e.response);
      } finally {
        this.afterSubmit();
      }
    },
    beforeSubmit() {
      this.submitting = true;
    },
    afterSubmit() {
      this.submitting = false;
    },
    async onSubmitSuccess() {
      await this.login({ username: this.username, password: this.password });
      this.$router.push(`/${this.$i18n.locale}`);
    },
    onSubmitError(response) {
      if (
        response.status === 406 &&
        response.data.username &&
        response.data.username.notUnique
      ) {
        this.duplicateUsernames.push(this.username);
        this.$refs.recaptcha.reset();
      }
    },
    toValidityState(validator) {
      if (!validator.$dirty || validator.$model.length === 0) {
        return null;
      }

      return !validator.$invalid;
    }
  }
};
</script>
