<template>
  <footer class="app-footer">
    <b-row class="mb-2">
      <b-col>
        <b-link
          v-for="link in links"
          :key="link.href"
          class="mr-2"
          :to="link.href"
        >
          {{ link.text }}
        </b-link>
      </b-col>
      <b-col class="text-right">
        <b-link v-if="$i18n.locale === 'uz'" href="/ru">Русский</b-link>
        <b-link v-else href="/uz">O'zbekcha</b-link>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex align-content-center">
        <p class="text-muted m-0" style="line-height: 2rem;">
          <small class="align-middle">
            &copy; 2012-{{ new Date() | moment("YYYY") }}
            Arxiv.uz
          </small>
        </p>
      </b-col>
      <b-col class="text-right">
        <a href="https://www.uz/ru/res/visitor/index?id=43462">
          <img
            src="https://cnt0.www.uz/counter/collect?id=43462&r=https%3A//referat.arxiv.uz/&pg=https%3A//referat.arxiv.uz/index.php%3Fdo%3Dfeedback%26user%3D2&c=Y&j=N&wh=393x786&px=24&js=1.3&col=F49918&t=ffffff&p=008ACC"
            alt="www.uz"
          />
        </a>
      </b-col>
    </b-row>
    <div class="small">
      {{ $t("developedBy") }}
      <a href="https://each.uz/">Foreach.Soft</a>
    </div>
  </footer>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Footer",
  data() {
    return {
      links: []
    };
  },
  computed: {
    ...mapState("footerLinks", ["loaded", "all"])
  },
  watch: {
    loaded: {
      handler(value) {
        if (value) {
          this.links = this.all;
        }
      },
      immediate: true
    }
  }
};
</script>

<style scoped></style>
