<template>
  <section class="main-breadcrumb">
    <hr />
    <b-breadcrumb :items="all"></b-breadcrumb>
    <hr />
  </section>
</template>

<script>
export default {
  name: "Breadcrumbs",
  props: {
    default: {
      type: Array,
      default() {
        return [];
      }
    },
    items: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    all() {
      return this.default.concat(this.items);
    }
  },
  watch: {
    "$route.name": {
      handler(name) {
        if (name !== "home") {
          this.steps.push({
            text: document.title,
            active: true
          });
        }
      }
    }
  }
};
</script>
