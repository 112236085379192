import Vue from "vue";
import Router from "vue-router";
import Home from "@/views/Home";
import Search from "@/views/Search";
import Document from "@/views/Document.vue";
import UserRegistration from "@/views/UserRegistration";
import UserSettings from "@/views/UserSettings";
import UserSubscriptions from "@/views/UserSubscriptions";
import NotFound from "@/views/NotFound";
import Category from "@/views/Category";
import Subject from "@/views/Subject";
import Page from "@/views/Page";
import YandexSearch from "@/views/YandexSearch";
import EmptyLayout from "@/layouts/Empty";

// services
import customMoment from "./moment";
import i18n from "@/i18n";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: `/${process.env.VUE_APP_DEFAULT_LOCALE}`
    },
    {
      path: `/:locale(${process.env.VUE_APP_LOCALES})`,
      component: EmptyLayout,
      children: [
        {
          path: "",
          name: "home",
          component: Home,
          meta: {
            title: "home_meta.title"
          }
        },
        {
          path: "search",
          name: "search",
          component: Search,
          meta: {
            title: "search.title"
          }
        },
        {
          path: "search/yandex",
          name: "search.yandex",
          component: YandexSearch,
          meta: {
            title: "search.title"
          }
        },
        {
          path: "user",
          component: EmptyLayout,
          children: [
            {
              path: "registration",
              name: "user.registration",
              component: UserRegistration,
              meta: {
                title: "registration.title"
              }
            },
            {
              path: "settings",
              name: "user.settings",
              component: UserSettings,
              meta: {
                title: "user.settings.title"
              }
            },
            {
              path: "subscriptions",
              name: "user.subscriptions",
              component: UserSubscriptions,
              meta: {
                title: "user.subscriptions"
              }
            }
          ]
        },
        {
          path: "documents/:category([a-z0-9-]+)",
          component: EmptyLayout,
          children: [
            {
              path: "",
              name: "category.view",
              component: Category,
              meta: {
                title: "categories.title"
              }
            },
            {
              path: ":subject([a-z0-9-]+)",
              name: "subject.view",
              component: Subject,
              meta: {
                title: "subjects.title"
              }
            }
          ]
        },
        {
          path:
            "documents/:category([a-z0-9-]+)/:subject([a-z0-9-]+)/:document([a-z0-9-]+)",
          name: "document.view",
          component: Document,
          meta: {
            title: ""
          }
        },
        {
          path: "page/:slug([a-z0-9-]+)",
          name: "page.view",
          component: Page,
          meta: {
            title: ""
          }
        }
      ]
    },
    {
      path: "*",
      name: "not.found",
      component: NotFound,
      meta: {
        title: "notFound.message"
      }
    }
  ]
});

router.beforeEach((to, from, next) => {
  const locale = location.pathname.split("/").filter(x => !!x)[0];

  if (process.env.VUE_APP_LOCALES.indexOf(locale) > -1) {
    i18n.locale = locale;
    customMoment.locale(locale === "uz" ? "uz-latn" : to.params.locale);
  }

  if (!to.params.locale && locale) {
    to.params.locale = locale;
    next(to);
  }

  document.title = i18n.t(to.meta.title) || process.env.name || document.title;
  next();
});

export default router;
