<template>
  <b-card
    border-variant="info"
    header-border-variant="info"
    header-bg-variant="info"
    header-text-variant="white"
    :header="plan.name"
    align="center"
    no-body
  >
    <b-list-group flush>
      <b-list-group-item
        class="d-flex justify-content-between align-content-center"
      >
        <p class="m-0">{{ $t("subscription.plan.price") }}:</p>
        <p class="m-0">
          {{ plan.price }}
          {{ $t("subscription.money.uzs") }}
        </p>
      </b-list-group-item>
      <b-list-group-item
        class="d-flex justify-content-between align-content-center"
      >
        <p class="m-0">{{ $t("subscription.plan.duration") }}:</p>
        <p class="m-0">
          {{ plan.interval }}
          {{ $t("subscription.plan.days") }}
        </p>
      </b-list-group-item>
    </b-list-group>
    <b-card-body>
      <a class="d-block mb-3 payme-payment" :href="payWith(plan.id, 'payme')">
        <img style="width: 160px;height: 20px;" :src="payWithPaymeImgSrc" />
      </a>
      <a class="click_logo d-block w-100" :href="payWith(plan.id, 'click')">
        <i></i>
        {{ $t("subscription.plan.payWith.click") }}
      </a>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: "SubscriptionPlan",
  props: {
    plan: {
      type: Object,
      default() {
        return {
          id: -1,
          name: "no-name",
          price: 0,
          interval: null
        };
      }
    }
  },
  computed: {
    payWithPaymeImgSrc() {
      const locale =
        this.$i18n.locale === "uz"
          ? "UZ_LATN_UZ"
          : this.$i18n.locale.toUpperCase();
      return `https://cdn.payme.uz/buttons/button_big_${locale}.svg`;
    }
  },
  methods: {
    payWith(plan, method) {
      return `/${this.$i18n.locale}/subscription/purchase/${plan}/${method}`;
    }
  }
};
</script>

<style scoped>
.payme-payment {
  cursor: pointer;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  background: linear-gradient(to top, #f1f2f2, white);
  width: 100%;
  padding: 5px 0;
}

.click_logo {
  padding: 4px 10px;
  cursor: pointer;
  color: #fff;
  line-height: 190%;
  font-size: 13px;
  font-family: Arial;
  font-weight: bold;
  text-align: center;
  border: 1px solid #037bc8;
  text-shadow: 0px -1px 0px #037bc8;
  border-radius: 4px;
  background: #27a8e0;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzI3YThlMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMxYzhlZDciIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#27a8e0), to(#1c8ed7));
  background: -webkit-linear-gradient(#27a8e0 0%, #1c8ed7 100%);
  background: -moz-linear-gradient(#27a8e0 0%, #1c8ed7 100%);
  background: -o-linear-gradient(#27a8e0 0%, #1c8ed7 100%);
  background: linear-gradient(#27a8e0 0%, #1c8ed7 100%);
  box-shadow: inset 0px 1px 0px #45c4fc;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#27a8e0', endColorstr='#1c8ed7',GradientType=0 );
  -webkit-box-shadow: inset 0px 1px 0px #45c4fc;
  -moz-box-shadow: inset 0px 1px 0px #45c4fc;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
.click_logo i {
  background: url(https://m.click.uz/static/img/logo.png) no-repeat top left;
  width: 30px;
  height: 25px;
  display: block;
  float: left;
}
</style>
